import {
  APP_INITIALIZER,
  ApplicationConfig,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { errorInterceptor } from './common/interceptors/error.interceptor';
import { authInterceptor } from './common/interceptors/auth.interceptor';

const DEFAULT_LANGUAGE = 'en';
export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    transloco.setActiveLang(DEFAULT_LANGUAGE);
    return transloco.load(DEFAULT_LANGUAGE);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([errorInterceptor, authInterceptor])),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimations(),
    provideTransloco({
      config: {
        availableLangs: ['ar', 'en'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: preloadTranslation,
      deps: [TranslocoService],
      multi: true,
    },
  ],
};
