import { Route } from '@angular/router';

export const authRoutes: Route[] = [
  {
    path: 'login',
    loadComponent() {
      return import('../pages/auth/login/login.component').then(
        c => c.LogInComponent
      );
    },
  },
];
