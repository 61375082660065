import { Route } from '@angular/router';
import { mainRoutes } from './layout/main.routes';
import { authRoutes } from './layout/auth.routes';
import { authGuard } from './common/guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('./layout/main/main.component').then(c => c.MainComponent),
    children: [...mainRoutes],
    canActivate: [authGuard],
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('./layout/auth/auth.component').then(c => c.AuthComponent),
    children: [...authRoutes],
  },
];
