import { Route } from '@angular/router';

export const mainRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests',
  },
  {
    path: 'requests',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-list/request-list.component'
      );
      return c.RequestListComponent;
    },
  },
  {
    path: 'requests/:id',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-details/request-details.component'
      );
      return c.RequestDetailsComponent;
    },
  },
  {
    path: 'requests/:flowId/:requestAction',
    async loadComponent() {
      const c = await import('../pages/main/request/request.component');
      return c.RequestComponent;
    },
  },
  {
    path: 'home',
    async loadComponent() {
      const c = await import('../pages/main/home/home.component');
      return c.HomeComponent;
    },
  },
  {
    path: 'my-inbox',
    async loadComponent() {
      const c = await import('../pages/main/my-inbox/my-inbox.component');
      return c.MyInboxComponent;
    },
  },
  {
    path: 'my-inbox/:stepId/:requestAction',
    async loadComponent() {
      const c = await import('../pages/main/request/request.component');
      return c.RequestComponent;
    },
  },
];
