import { HttpInterceptorFn } from '@angular/common/http';
import { AppStorage } from '../enums/app-storage';
import { inject } from '@angular/core';
import { ManageCookiesService } from '../services/cookies-service/manage-cookies.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const cookiesService = inject(ManageCookiesService);
  const token = cookiesService.getCookie(AppStorage.token) || '';
  req = req.clone({
    headers: req.headers
      .set('Authorization', `Bearer ${token}`)
      .set('Accept-Language', 'en'),
  });

  return next(req);
};
